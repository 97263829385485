<template>
    <div class="section index-section" key="tatvorwurf">
        <div class="section-title">
          <h3 class="mb-2">1. Was wird Dir vorgeworfen?</h3>
          <p class="small">Bitte gib an, um was für einen Tatvorwurf es sich handelt. Diese Information findest Du auf Deinem Bußgeldbescheid.</p>
        </div>
        <div class="form-container">
            <div class="form-card">
            <input type="radio" v-model="user.accusation" name="speed-radio" value="Geschwindigkeitsverstoß" id="question-1-1">
            <label for="question-1-1" v-on:click="next()"><span class="label-icon"><img src="@/assets/images/icons8-speedometer.svg"></span><span>Geschwindigkeitsverstoß</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.accusation" name="distance-radio" value="Abstandsverstoß" id="question-1-2">
            <label for="question-1-2" v-on:click="next()"><span class="label-icon"><img src="@/assets/images/icons8-sedan.svg"></span><span>Abstandsverstoß</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.accusation" name="redlight-radio" value="Rotlichtverstoß" id="question-1-3">
            <label for="question-1-3" v-on:click="next()"><span class="label-icon"><img src="@/assets/images/icons8-traffic-light.svg"></span><span>Rotlichtverstoß</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.accusation" name="mobile-drugs-parking-radio" value="Handy / Drogen / Parken" id="question-1-4">
            <label for="question-1-4" v-on:click="next()"><span class="label-icon"><img src="@/assets/images/icons-beer.svg"></span><span>Handy / Drogen / Parken</span></label>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'Accusation',
  computed: mapState({
    user: (state) => state.user,
  }),
  methods: {
    next() {
      this.$emit('next');
    }
  },
  created() {
    this.user.accusation = '';
  }
}
</script>